<template>
    <div v-if="ticket.attributes.type == 'elockers'">
        <div class="confirmation-top">
            <div class="flex justify-between items-start w-full">
                <div class="">
                    <h3>{{ $t('lockers.locker') }}: {{ ticket.attributes.description }}</h3>
                    <div>{{ $t('fields.pincode') }}: {{ ticket.attributes.pincode }}</div>
                </div>
            </div>

            <div>


                <a class="btn btn-primary btn-whatsapp" :href="`whatsapp://send?text=Locker: ${ticket.attributes.description}, Pincode: ${ticket.attributes.pincode}`"
                   data-action="share/whatsapp/share">
                    Share
                </a>
            </div>
        </div>

        <div class="confirmation-content">
            <div><span>Area: </span><span>{{ ticket.attributes.details.area_name }}</span></div>
            <div><span>{{ $t('lockers.color') }} </span>
                <span>{{ ticket.attributes.details.cabinet_color }}
                        <div class="locker-color"
                            :style="{backgroundColor: ticket.attributes.details.cabinet_color}">
                    </div>
                </span>
            </div>
            <div><span>{{ $t('fields.date') }}: </span><span>{{ ticket.attributes.details.event_date }}</span></div>
            <div><span>{{ $t('lockers.name_event') }}: </span><span>{{
                    ticket.attributes.details.event_name
                }}</span></div>
            <div><span>{{ $t('lockers.name_location') }}:</span><span>{{
                    ticket.attributes.details.location_name
                }}</span></div>
            <div><span>{{ $t('lockers.type') }}: </span><span>{{ ticket.attributes.details.type }}</span></div>

            <button @click="openLockerMaintenanceModel(ticket.attributes.locker_id)" class="btn btn-underline mt-2">
                {{ $t("locker.maintenance.problem") }}
            </button>
        </div>
    </div>
    <modal-locker-defect-form ref="locker_defect" :locker="5"/>
</template>

<script>
import ModalLockerDefectForm from '../components/ModalLockerDefectForm.vue';

export default {
    name: 'Ticket',
    props: ['ticket'],
    components: {
        ModalLockerDefectForm,
    },
    methods: {
        openLockerMaintenanceModel(locker_id) {
            this.$refs.locker_defect.open(locker_id);
        },
    }
}
</script>
