<template>
    <transition name="blur-background">
        <blur v-if="show">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-login fixed-modal-wrapper" v-if="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

                <div
                    class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg w-full radius-anchor"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                    <div class="p-4 pt-8">
                        <div class="modal-close">
                            <button @click="close()" class="modal-close" aria-label="Close">
                                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div class="text-center">
                            <h2 class="text-3xl font-bold leading-none px-4">{{ $t('locker.maintenance.problem') }}</h2>
                            <p class="mt-2">{{ $t('locker.maintenance.form.fillout') }}</p>
                        </div>
                        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                            <div class="p-4">
                                <div class="mt-4">
                                    <label for="description">{{ $t('locker.problem') }}</label>
                                    <Field v-model="form.description" name="description" as="select" class="h-10 py-1 px-2 form-select w-full">
                                        <option value="" selected="selected" disabled>{{ $t('locker.select.problem') }}</option>
                                        <option value="Kan niet dicht (gaat weer open)">{{ $t('locker.issue.cant.close') }}</option>
                                        <option value="Slot geeft geen reactie">{{ $t('locker.issue.lock.no.reaction') }}</option>
                                        <option value="Deur klemt">{{ $t('locker.issue.lock.door.jams') }}</option>
                                        <option value="Code werkt niet">{{ $t('locker.issue.code.not.working') }}</option>
                                    </Field>
                                </div>
                                <div class="my-4">
                                    <label for="notes">{{ $t('locker.notes') }}</label>
                                    <Field type="text" name="notes" v-model="form.notes" class="py-1 px-2 block w-full outline-none focus:outline-none" :class="{'error': errors.notes}" />
                                </div>
                            </div>
                            <div class="flex justify-between">
                                <button type="submit" class="btn btn-primary inline-flex w-full justify-center items-center px-6 py-3 text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                    {{ $t('locker.submit')}}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { Form, Field } from 'vee-validate';
import * as yup from "yup";

import * as _api from '../lib/api';
import * as _notification from '../lib/notification';

import Blur from "./Blur";

export default {
    components: {
        Blur,
        Form,
        Field,
    },
    props: ['tickets'],
    data() {
        const schema = yup.object().shape({
            description: yup.string().required().max(255),
            notes: yup.string().nullable().max(255),
        });

        return {
            schema,
            errors: {},
            form: {
                locker_id: '',
                description: '',
                notes: '',
            },
            saving: false,
            show: false,
        }
    },
    methods: {
        open(locker_id) {
            this.form.locker_id = locker_id;
            this.show = true;
        },

        close() {
            this.show = false;
            this.clearForm();
        },

        async onSubmit() {
            if (this.saving === true) {
                return;
            }
            this.saving = true;
            
            const response = await _api.post('elockers/locker/maintenance', this.form);

            if (response.result === 'success') {
                _notification.set(this.$t('notification.locker.maintenance.success'), 'success');
            } else {
                _notification.set(this.$t('notification.locker.maintenance.failed'), 'error');
            }

            this.saving = false;
            this.close();
        },

        clearForm() {
            this.form.locker_id = '';
            this.form.description = '';
            this.form.notes = '';
        }
    },
}
</script>
